import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
//import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                {/** Qué es¿? */}
                <Title>Curs Online Policia Local el Prat de Llobregat 2024</Title>
                <Text>
                    Arriba el millor curs per preparar l'accés a la policia local del Prat.
                    <br />
                    <br />
                    Tras l'èxit de l'anterior convocatòria on la majoria d'alumnes aptes van ser de
                    iOpos Acadèmia, inclòs el número 1.
                    <br />
                    <br />
                    Torna un curs més renovat i específic
                    <br />
                    <br />
                    Un curs viu i en constant evolució amb tots els continguts exigits a les bases
                    per posicionar-se entre els millors.
                    <br />
                    <br />
                    Contingut:
                    <br />
                    <br />
                    - Actualitat setmanal en forma de notícies, test per posar-te a prova i un vídeo
                    explicatiu mensual.
                    <br />
                    <br />
                    - Assignatura de cultura general bàsica i test
                    <br />
                    <br />
                    - Assignatura de cultura general AVANÇADA
                    <br />
                    <br />
                    - Assignatura de cultura general per a CRACKS amb videos
                    <br />
                    <br />
                    - El municipi i les seves curiositats.
                    <br />
                    <br />
                    - El consistori
                    <br />
                    <br />
                    - Premis 2024
                    <br />
                    <br />
                    - Esports 2024
                    <br />
                    <br />
                    - Personalitats 2024
                    <br />
                    <br />
                    - 12 Exàmens oficials
                    <br />
                    <br />
                    - Els 40 temes exigits a les bases, amb test e idees força.
                    <br />
                    <br />
                    El curs estarà obert fins al dia de l'examen
                    <br />
                    <br />
                    Entra ara i avança al teu ritme!
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                        <iframe
                            title={item.title}
                            width="100%"
                            height="600px"
                            src="https://www.youtube.com/embed/BuPaXhuLZbY"
                            frameBorder={"0"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen={true}
                        />
                    </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
